import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  image as ImagePropType,
  computed as ComptuedPropType,
  responsiveFlavors as ResponsiveFlavorsPropType,
} from 'lib/CustomPropTypes';
import { IMAGE_SIZES, getWidthAndHeightFromAIMSFlavor } from 'lib/imageUtils';
import { replaceAmpersands } from 'lib/stringUtils';
import {
  getTeaseForRatio,
  getTeaseByline,
} from 'lib/teaseUtils';


import styles from './styles.module.scss';


const getDisplayClass = (size, isDisplayed) => {
  const display = isDisplayed ? 'db' : 'dn';
  const toolkitMap = {
    s: `${display}`,
    m: `${display}-m`,
    l: `${display}-l`,
    xl: `${display}-xl`,
  };
  return toolkitMap[size];
};

const shouldDisplayImageCredit = (teaseSize, retina) => {
  const { width: teaseWidth, height: teaseHeight } = getWidthAndHeightFromAIMSFlavor(teaseSize);
  const minWidth = retina ? 300 : 150;
  // Note: 480 is a 'magic number' due to how we treat 2/3/4 ups
  const largeWidth = retina ? 900 : 480;
  const isSquare = teaseSize ? (teaseWidth === teaseHeight) : false;
  const isSmallSquare = teaseSize ? (isSquare && teaseWidth < largeWidth) : false;
  const isBeforeMinimumWidth = teaseSize ? teaseWidth < minWidth : false;

  if (isSmallSquare || isBeforeMinimumWidth) {
    return false;
  }
  return true;
};

const getFromTease = (tease) => tease && getTeaseByline(tease);

const getFromComputed = (computedValues, sizes) => {
  const uniqueCredits = {};
  const imageSizeKeys = Object.keys(sizes);

  // create default display array
  const defaultDisplayClasses = imageSizeKeys.map((size) => getDisplayClass(size, false));

  imageSizeKeys.forEach((size, index) => {
    const { width, height } = getWidthAndHeightFromAIMSFlavor(sizes[size]);
    const tease = getTeaseForRatio(computedValues, (width === height));
    const creditText = getTeaseByline(tease);

    if (!uniqueCredits[creditText]) {
      uniqueCredits[creditText] = {
        displays: defaultDisplayClasses.slice(0),
        ratio: `1x${width / height}`,
      };
    }

    const newDisplayClass = getDisplayClass(size, true);
    // overwrite default display class if it should be shown
    uniqueCredits[creditText].displays[index] = newDisplayClass;
  });

  // check to see if there are multiple unique credits
  const creditStrings = Object.keys(uniqueCredits);
  if (creditStrings.length === 1) {
    return creditStrings[0];
  }

  // if there is more then one credit string
  const spans = creditStrings.map((string) => {
    const displayClasses = uniqueCredits[string].displays;
    const uniqueKey = uniqueCredits[string].ratio;
    return (
      <span
        key={`credit-${uniqueKey}`}
        data-testid={`image-credit-content-${uniqueKey}`}
        className={classNames(
          `test__imageCredit__content--${uniqueKey}`,
          displayClasses,
        )}
      >
        {string}
      </span>
    );
  });

  return spans;
};

function ImageCredit({
  responsiveFlavors,
  type = '',
  retina = false,
  teaseImage = {},
  computedValues = null,
}) {
  if (type === 'video' || type === 'relatedRecipe') {
    return null;
  }

  let content = null;
  if (computedValues) {
    content = getFromComputed(computedValues, responsiveFlavors);
  } else if (teaseImage) {
    content = getFromTease(teaseImage);
  }

  if (content) {
    let defaultDisplay = shouldDisplayImageCredit(responsiveFlavors.s, retina);

    const displayClasses = IMAGE_SIZES.map((size) => {
      const teaseSize = responsiveFlavors[size];
      const isDisplayed = teaseSize ? shouldDisplayImageCredit(teaseSize, retina) : defaultDisplay;
      if (defaultDisplay !== isDisplayed) {
        defaultDisplay = isDisplayed;
      }
      return getDisplayClass(size, isDisplayed);
    });

    return (
      <div data-testid="image-credit" className={classNames('test__imageCredit', 'image-credit__text', styles.imageCredit, displayClasses)}>
        {replaceAmpersands(content)}
      </div>
    );
  }
  return null;
}

ImageCredit.propTypes = {
  type: PropTypes.string,
  retina: PropTypes.bool,
  // Legacy single tease
  teaseImage: ImagePropType,
  // Teases by aspect ratio
  computedValues: ComptuedPropType,
  responsiveFlavors: ResponsiveFlavorsPropType.isRequired,
};


export default ImageCredit;
