import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { responsiveFlavors as ResponsiveFlavorsPropType } from 'lib/CustomPropTypes';

import NativeLazyLoad from 'components/NativeLazyLoad';
import ImageCredit from 'components/ImageCredit';
import { Picture } from 'components/Picture';
import { Placeholder } from 'components/Picture/Placeholder';
import TypeIcon from 'components/TypeIcon';

import styles from './styles.module.scss';

/**
 * If lazyloading is set, returns element wrapped with NativeLazyLoad component
 * otherwise only child element is returned
 * @param {element|object} children
 * @param {bool} lazyloaded whether the child elements should be lazy loaded
 * @param {*} props data that is passed down in the optional NativeLazyLoad wrapper
 * @returns {React.ReactElement}
 */
function Wrapper({
  children = {},
  lazyloaded = true,
  ...props
}) {
  if (lazyloaded) {
    return <NativeLazyLoad {...props}>{children}</NativeLazyLoad>;
  }
  return children;
}

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  lazyloaded: PropTypes.bool,
};

const TeasePicture = ({
  responsiveFlavors = {},
  className,
  lazyload = true,
  previewRedirect,
  type,
  height,
  width,
  hideCredit = false,
  isSportsVideo,
  ...rest
}, context = {}) => {
  if (!responsiveFlavors) return null;

  const remainingProps = {
    placeholder: false,
    ...rest,
  };

  const {
    computedValues,
    placeholder,
    placeholderColor,
  } = remainingProps;

  const {
    teaseImage,
  } = computedValues || {};

  if (!teaseImage && placeholder) {
    return (
      <Placeholder
        responsiveFlavors={responsiveFlavors}
        color={placeholderColor}
      />
    );
  }

  let pictureHeight;
  let pictureWidth;
  if (height && width) {
    pictureHeight = height;
    pictureWidth = width;
  } else if (teaseImage?.height && teaseImage?.width) {
    pictureHeight = teaseImage.height;
    pictureWidth = teaseImage.width;
  }

  const { topOfPagePackages } = context;
  const lazyloaded = lazyload && !topOfPagePackages;
  return (
    <>
      <Wrapper
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        lazyloaded={lazyloaded}
      >
        <Picture
          className={classNames(
            'teasePicture',
            {
              [className]: className,
              [styles.container]: !isSportsVideo,
              [styles.sportsContainer]: isSportsVideo,
            },
          )}
          lazyloaded={lazyloaded}
          responsiveFlavors={responsiveFlavors}
          originalHeight={pictureHeight}
          originalWidth={pictureWidth}
          {...remainingProps}
        />
      </Wrapper>
      <div className={styles.typeWrapper}>
        <TypeIcon type={isSportsVideo} />
      </div>
      {!hideCredit && (
        <ImageCredit
          type={type}
          responsiveFlavors={responsiveFlavors}
          {...remainingProps}
        />
      )}
    </>
  );
};

TeasePicture.contextTypes = {
  topOfPagePackages: PropTypes.bool,
};

TeasePicture.propTypes = {
  responsiveFlavors: ResponsiveFlavorsPropType,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  lazyload: PropTypes.bool,
  placeholder: PropTypes.bool,
  previewRedirect: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  hideCredit: PropTypes.bool,
  isSportsVideo: PropTypes.string,
};

export default TeasePicture;
