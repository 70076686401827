const leftPad = require('left-pad');
const parseISODuration = require('parse-iso-duration');
const dayjs = require('dayjs');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

const jstz = require('jstz');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// Use AP style for months on timestamps, per News editorial request:
// Abbreviate only the long months: Jan., Feb., Aug., Sept., Oct., Nov., Dec.
// The rest of the months are spelled out: March, April, May, June, July
const AP_MONTH_MAP = {
  1: 'Jan.',
  2: 'Feb.',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'Aug.',
  9: 'Sept.',
  10: 'Oct.',
  11: 'Nov.',
  12: 'Dec.',
};
const AP_MONTHS = Object.values(AP_MONTH_MAP);

const MONTH_MAP = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
const MONTHS = Object.values(MONTH_MAP);

const DAY_MAP = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
const DAYS = Object.values(DAY_MAP);

const pad = (number) => leftPad(String(number), 2, '0');

const getSecondsFromMS = (ms) => Math.floor((ms / 1000) % 60);

const getMinutesFromMS = (ms) => Math.floor((ms / (1000 * 60)) % 60);

const getHoursFromMS = (ms) => Math.floor((ms / (1000 * 60 * 60)) % 24);

// Finds a token within the format string
const findToken = (arr, t) => arr.findIndex((i) => i.includes(t));

/**
 * @returns {String}
 */
const getRuntimeIANATimeZone = () => {
  const timeZoneInstance = jstz.determine();
  const timeZoneName = timeZoneInstance.name();

  return timeZoneName;
};

/**
 * Format date using IANA time zone and customizable format string
 * @param {String} dateString
 * @param {String} [timeZone] iana time zone
 * @returns {Object} date object with { year, month, day, hours, minutes, ... }
 */
const parseDate = (dateString, timeZone) => {
  if (!timeZone) {
    timeZone = getRuntimeIANATimeZone(); // eslint-disable-line no-param-reassign
  }

  const parsedDate = dayjs(dateString).tz(timeZone);

  return {
    year: parsedDate.year(),
    month: parsedDate.month(),
    day: parsedDate.date(),
    dayOfWeek: parsedDate.day(),
    hours: parsedDate.hour(),
    minutes: parsedDate.minute(),
    seconds: parsedDate.second(),
    milliseconds: parsedDate.millisecond(),
  };
};

// Fallback if translation object is not passed to date function
const langaugeStub = {
  t: (key) => key,
};

/**
 * Format date in AP style
 * @param {String} dateString
 * @param {String} [timeZone] iana time zone
 * @param {object} [options]
 * @param {string} [options.format]
 * @param {boolean} [options.useAP]
 * @param {boolean} [options.commaAfterDayOfWeek]
 * @param {boolean} [options.commaAfterDay]
 * @returns {String}
 * NOTE: the format string does not allow extra characters like commas and delimiters.
 */
const getFormattedDateString = (dateString, timeZone, options = {}, language = langaugeStub) => {
  const {
    format = 'M D YYYY',
    useAP = true,
    commaAfterDay = true,
    commaAfterDayOfWeek = true,
  } = options || {};

  const formatTokens = format.split(' ');

  let dayjsInstance = dayjs(dateString);

  if (timeZone) {
    dayjsInstance = dayjsInstance.tz(timeZone);
  }

  const digitFormattedDate = dayjsInstance.format(format);
  const formattedDateTokens = digitFormattedDate.split(' ');

  // Replace month with proper format
  const monthIndex = findToken(formatTokens, 'M');
  if (monthIndex !== -1) {
    const monthDigit = formattedDateTokens[monthIndex];
    const monthName = (useAP ? AP_MONTH_MAP : MONTH_MAP)[monthDigit];
    formattedDateTokens[monthIndex] = language.t(monthName, { keySeparator: false });
  }

  // Replace day of week with proper format
  const dayOfWeekIndex = findToken(formatTokens, 'd');
  if (dayOfWeekIndex !== -1) {
    const dayOfWeekDigit = formattedDateTokens[dayOfWeekIndex];
    const dayOfWeekName = language.t(DAY_MAP[dayOfWeekDigit], { keySeparator: false });
    formattedDateTokens[dayOfWeekIndex] = `${dayOfWeekName}${commaAfterDayOfWeek ? ',' : ''}`;
  }

  // Replace day of month with proper format
  const dayIndex = findToken(formatTokens, 'D');
  if (dayIndex !== -1) {
    const dayDigit = formattedDateTokens[dayIndex];
    formattedDateTokens[dayIndex] = `${dayDigit}${commaAfterDay ? ',' : ''}`;
  }

  return formattedDateTokens.join(' ');
};

/**
 * @param {String} dateString
 * @param {String} timeZone iana time zone
 * @returns {String}
 */
const getFormattedTimeString = (dateString, timeZone) => {
  let dayjsInstance = dayjs(dateString);

  if (timeZone) {
    dayjsInstance = dayjsInstance.tz(timeZone);
  }

  return dayjsInstance.format('h:mm A z');
};

/**
 * @param {String} dateString
 * @param {String} timeZone iana time zone
 * @param {String} delimiter
 * @returns {String}
 */
const getFormattedDateTimeString = (dateString, timeZone, delimiter = ' / ', language) => {
  const formattedDateString = getFormattedDateString(dateString, timeZone, {}, language);
  const formattedTimeString = getFormattedTimeString(dateString, timeZone);

  return `${formattedDateString}${delimiter}${formattedTimeString}`;
};

/**
 * @param {String} dateString
 * @param {String} timeZone iana time zone
 * @returns {String}
 */
const getFormattedMonthAndDayString = (dateString, timeZone, language) => getFormattedDateString(
  dateString,
  timeZone,
  {
    format: 'M D',
    commaAfterDay: false,
  },
  language,
);

/**
 * @param {String} dateString
 * @param {String} timeZone iana time zone
 * @returns {String}
 */
const getFormattedDateYearString = (dateString, timeZone) => getFormattedDateString(
  dateString,
  timeZone,
  { format: 'YYYY' },
);

/**
 * Calculate time ellapsed since date in date units
 * @param {String} dateString
 */
const timeFrom = (dateString) => {
  const date = new Date(dateString);
  const millisec = Math.abs(Date.now() - date.getTime());
  const minutes = (millisec / (1000 * 60)).toFixed(0);
  const hours = (millisec / (1000 * 60 * 60)).toFixed(0);
  const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(0);

  if (minutes < 60) {
    return `${minutes}m`;
  } if (hours < 24) {
    return `${hours}h`;
  }

  return `${days}d`;
};

/**
 * Gets the hours, minutes, seconds duration from ISO Duration
 * @param {*} duration
 */
const getDuration = (duration) => {
  try {
    let ms = duration;

    if (typeof ms === 'string') {
      if (!ms.startsWith('PT')) {
        ms = `PT${ms}`;
      }
      ms = parseISODuration(ms);
    }

    if (!ms) {
      return null;
    }

    return {
      hour: getHoursFromMS(ms),
      minute: getMinutesFromMS(ms),
      second: getSecondsFromMS(ms),
    };
  } catch (e) {
    return null;
  }
};

/**
 * Calculates seconds from ISO 8601 duration string
 * @param {string} duration
 * @returns {number} - returns seconds like 140
 */
const timeToSeconds = (duration) => {
  const fullDuration = getDuration(duration);
  if (!fullDuration) {
    return 0;
  }

  const { hour, minute, second } = fullDuration;
  return hour * 3600 + minute * 60 + second;
};

/**
 * Calculate and format duration
 * @param {*} duration
 * @returns {string} - returns hours:minutes:seconds like 2:30:29
 */
const formatDuration = (duration) => {
  const fullDuration = getDuration(duration);
  if (!fullDuration) {
    return null;
  }

  const { hour, minute, second } = fullDuration;

  if (!hour) {
    return `${pad(minute)}:${pad(second)}`;
  }
  return `${pad(hour)}:${pad(minute)}:${pad(second)}`;
};

/**
 * Calculate, format duration with "hours" and "mins"
 * @param {*} duration
 * @returns {string} - returns {hour}hour(s) {minute}minute(s) {second}second(s)
 */
const getDurationString = (duration) => {
  const fullDuration = getDuration(duration);
  if (!fullDuration) {
    return null;
  }

  const { hour, minute, second } = fullDuration;

  let durationBuilder = '';
  if (hour && hour > 0) {
    durationBuilder += `${hour} hour`;
    if (hour > 1) {
      durationBuilder += 's';
    }
  }
  if (minute && minute > 0) {
    durationBuilder += ` ${minute} minute`;
    if (minute > 1) {
      durationBuilder += 's';
    }
  }
  if (second && second > 0) {
    durationBuilder += ` ${second} second`;
    if (second > 1) {
      durationBuilder += 's';
    }
  }

  durationBuilder = durationBuilder.trim();
  return durationBuilder;
};

const getShortDurationString = (duration) => {
  if (!duration) {
    return null;
  }

  let durationStr = getDurationString(duration);
  if (!durationStr) {
    return null;
  }

  durationStr = durationStr.replace('minute', 'min');
  durationStr = durationStr.replace('hour', 'hr');
  if (durationStr.includes('seconds')) {
    durationStr = durationStr.replace('seconds', 'sec');
  } else {
    durationStr = durationStr.replace('second', 'sec');
  }
  return durationStr;
};

/**
 * @param {*} d
 * @param {*} offset
 */
const addDays = (d, offset) => {
  const date = new Date(d.valueOf());
  date.setDate(date.getDate() + offset);
  return date;
};

/**
 * @param {String} dateString
 */
const formatAsISO = (dateString) => {
  const date = new Date(dateString);
  if (Number.isNaN(Date.parse(date))) {
    return '';
  }
  return date.toISOString();
};

/**
 * @param {object} dateA Date object || millisecond int
 * @param {object} dateB Date object || millisecond int
 */
const getHourDifference = (dateA, dateB) => (
  Math.abs(dateA - dateB) / 36e5
);

/**
 * Is the given unix timestamp older than now.
 * @param {String} timestamp
 */
const hasExpired = (timestamp) => new Date(parseInt(timestamp, 10)) < new Date();

/**
 * @param {object} dateA Date object || millisecond int
 * @param {object} dateB Date object || millisecond int
 */
const isBefore = (datetime, maxMilliSeconds) => {
  const date = new Date(datetime);
  const millisec = Math.abs(Date.now() - date.getTime());

  if (millisec < maxMilliSeconds) {
    return true;
  }

  return false;
};

/**
 * @param {String} dateTime
 * @returns {Object}
 */
const getDateTime = (dateTime) => new Date(dateTime);

/**
 * Provides seconds
 * @requires dayjs
 * @param {String} datePublished - from article object
 * @returns {number}
 */
const getSecondsElapsed = (datePublished) => dayjs().diff(datePublished, 's');

module.exports = {
  // constants
  AP_MONTH_MAP,
  AP_MONTHS,
  MONTH_MAP,
  MONTHS,
  DAY_MAP,
  DAYS,
  // functions
  addDays,
  timeToSeconds,
  formatAsISO,
  formatDuration,
  getDateTime,
  getDuration,
  getDurationString,
  getShortDurationString,
  getFormattedDateString,
  getFormattedDateTimeString,
  getFormattedDateYearString,
  getFormattedMonthAndDayString,
  getFormattedTimeString,
  getHourDifference,
  getRuntimeIANATimeZone,
  getSecondsElapsed,
  hasExpired,
  isBefore,
  parseDate,
  timeFrom,
};
